import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Gallery from '../components/Gallery';
import image_1 from '../assets/images/image_1.png';
import image_2 from '../assets/images/image_2.png';
import image_3 from '../assets/images/image_3.png';
import image_6 from '../assets/images/image_6.png';
import image_7 from '../assets/images/image_7.png';
import image_8 from '../assets/images/image_8.png';
import image_9 from '../assets/images/image_9.png';
import image_10 from '../assets/images/image_10.png';
import image_11 from '../assets/images/image_11.png';
import image_12 from '../assets/images/image_12.png';
import image_13 from '../assets/images/image_13.png';
import image_15 from '../assets/images/image_15.png';
import image_16 from '../assets/images/image_16.png';
import image_17 from '../assets/images/image_17.png';
import image_18 from '../assets/images/image_18.png';
import image_19 from '../assets/images/image_19.png';
import image_20 from '../assets/images/image_20.png';
import image_21 from '../assets/images/image_21.png';
import image_22 from '../assets/images/image_22.png';
import image_23 from '../assets/images/image_23.png';
import image_24 from '../assets/images/image_24.png';
import image_25 from '../assets/images/image_25.png';
import image_26 from '../assets/images/image_26.png';
// import image_27 from '../assets/images/image_27.png';
import image_28 from '../assets/images/image_28.png';
import image_29 from '../assets/images/image_29.png';
import image_30 from '../assets/images/image_30.png';
import image_31 from '../assets/images/image_31.png';
import image_32 from '../assets/images/image_32.png';
import image_33 from '../assets/images/image_33.png';
import image_34 from '../assets/images/image_34.png';
import image_35 from '../assets/images/image_35.png';
import image_36 from '../assets/images/image_36.png';
import image_37 from '../assets/images/image_37.png';
import image_38 from '../assets/images/image_38.png';
import image_39 from '../assets/images/image_39.png';
// import image_40 from '../assets/images/image_40.png';
import image_41 from '../assets/images/image_41.png';
import image_42 from '../assets/images/image_42.png';
import image_43 from '../assets/images/image_43.png';
import image_44 from '../assets/images/image_44.png';
import image_45 from '../assets/images/image_45.png';
import image_46 from '../assets/images/image_46.png';

import before_after1 from '../assets/images/before_after1.png';

const residentialImages = [
  {
    src: image_1,
    description: ''
  },
  {
    src: image_2,
    description: ''
  },
  {
    src: before_after1,
    description: 'Before (left) and After (right)'
  },
  {
    src: image_3,
    description: ''
  },
  {
    src: image_6,
    description: ''
  },
  {
    src: image_7,
    description: ''
  },
  {
    src: image_8,
    description: ''
  },
  {
    src: image_9,
    description: ''
  },
  {
    src: image_10,
    description: ''
  },
  {
    src: image_8,
    description: ''
  },
  {
    src: image_15,
    description: ''
  },
  {
    src: image_16,
    description: ''
  },
  {
    src: image_21,
    description: ''
  },
  {
    src: image_22,
    description: ''
  },
  {
    src: image_23,
    description: ''
  },
  {
    src: image_24,
    description: ''
  },
  {
    src: image_25,
    description: ''
  },
  {
    src: image_26,
    description: ''
  },
  
  {
    src: image_28,
    description: ''
  },
  {
    src: image_29,
    description: ''
  },
  {
    src: image_30,
    description: ''
  },
  {
    src: image_31,
    description: ''
  },
  {
    src: image_32,
    description: ''
  },
  {
    src: image_35,
    description: ''
  },
  {
    src: image_36,
    description: ''
  },
  {
    src: image_37,
    description: ''
  },
  {
    src: image_38,
    description: ''
  },
  
  {
    src: image_41,
    description: ''
  },
  
  {
    src: image_43,
    description: ''
  },
  

];

// need to get permission from companies before using this
const commercialImages = [
  {
    src: image_11,
    description: ''
  },
  {
    src: image_12,
    description: ''
  },
  {
    src: image_13,
    description: ''
  },
  {
    src: image_17,
    description: ''
  },
  {
    src: image_18,
    description: ''
  },
  {
    src: image_19,
    description: ''
  },
  {
    src: image_20,
    description: ''
  },
  //{
   // src: image_27,
    //description: ''
  //},
  {
    src: image_33,
    description: ''
  },
  {
    src: image_34,
    description: ''
  },
  {
    src: image_39,
    description: ''
  },
  // {
  //   src: image_40,
  //   description: ''
  // },
  {
    src: image_42,
    description: ''
  },
  {
    src: image_44,
    description: ''
  },
  {
    src: image_45,
    description: ''
  },
  {
    src: image_46,
    description: ''
  }
]

// TODO: add ...commercialImages back in when permission has been obtained
const allImages = [...residentialImages, ...commercialImages]

// Function to preload images
const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image.src;
  });
};

const PageContainer = styled.div`
  padding: 2rem;
`;

const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 2.0rem;
  color: #2e2e2e;
  font-weight: bold;
`;

const FilterButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;

  button {
    margin: 0 0.5rem;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    color: #2e2e2e;
    background: #f5f5f5;
    border: none;
    border-radius: 20px; /* Rounded edges */
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: all 0.3s ease; /* Smooth transition */

    &:hover {
      background: #e0e0e0;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
    }

    &.active {
      background: #2e2e2e;
      color: white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      background: #ccc; /* Gray background for disabled state */
      color: #999;
      cursor: not-allowed;
    }

    
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

function GalleryPage() {
  const [selectedFilter, setSelectedFilter] = useState("all");

  // Preload images on component mount
  useEffect(() => {
    preloadImages(allImages);
  }, []);

  const getFilteredImages = () => {
    if (selectedFilter === "residential") return residentialImages;
    if (selectedFilter === "commercial") return commercialImages;
    return allImages;
  };

  return (
    <PageContainer>
      <PageTitle>Our Recent Projects</PageTitle>
      <FilterButtons>
          <button
            className={selectedFilter === "all" ? "active" : ""}
            onClick={() => setSelectedFilter("all")}
          >
            See All Photos
          </button>
          
        <button
          className={selectedFilter === "residential" ? "active" : ""}
          onClick={() => setSelectedFilter("residential")}

        >
          Residential
        </button>
        <button
          className={selectedFilter === "commercial" ? "active" : ""}
          onClick={() => setSelectedFilter("commercial")}

        >
          Commercial
        </button>
      </FilterButtons>
      <Gallery images={getFilteredImages()} />
    </PageContainer>
  );
}

export default GalleryPage;