import React from 'react';
import styled from 'styled-components';

const ServiceOverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const LeftSection = styled.div`
  flex: 3;
  margin-right: 2rem;
`;

const RightSection = styled.div`
  flex: 1;
  background-color: #f8f8f8; /* Light grey background for the right section */
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Subheading = styled.p`
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  color: #555;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1.5rem;
`;

const DetailHeading = styled.h3`
  font-size: 1rem;
  color: #2e2e2e;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const DetailParagraph = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 1rem;
`;

const ContactInfo = styled.div`
  margin-top: 1.5rem;
`;

const ContactLink = styled.a`
  color: #2a77f1;
  text-decoration: none;
  font-weight: bold;
`;

function ServiceOverview() {
  return (
    <ServiceOverviewContainer>
      <LeftSection>
        <Heading>General Glass & Window, Inc. is a window and door manufacturer with over four decades of experience in the industry.</Heading>
        <Subheading>
          Based in Lake Worth, Florida, we serve Broward, Palm Beach, and Martin counties specializing in all aspects of glass and glazing processes.
        </Subheading>
        <Paragraph>
          We use only national brand-name products and manufacturers that provide quality, energy-efficient solutions to your window and door needs.
          At General Glass and Window, we pride ourselves on our ability to consistently deliver good service.
        </Paragraph>
        <Paragraph>
        Call us and we can answer all your questions and help you find the perfect replacement windows and patio doors for your home or business.
        </Paragraph>
      </LeftSection>
      <RightSection>
        <DetailHeading>Office Hours</DetailHeading>
        <DetailParagraph>Mon - Fri: 7am - 3:30pm</DetailParagraph>
        <DetailParagraph>Sat and Sun: Closed</DetailParagraph>

        <DetailHeading>Office Location</DetailHeading>
        <DetailParagraph>3646 23rd Ave. So., Suite #105, Lake Worth, FL 33461</DetailParagraph>

        <DetailHeading>Contact</DetailHeading>
        <ContactInfo>
          <DetailParagraph>
            <ContactLink href="mailto:contact@generalglassandwindow.com">contact@generalglassandwindow.com</ContactLink>
          </DetailParagraph>
          <DetailParagraph>
            <ContactLink href="tel:+15615826719">(561) 582-6719</ContactLink>
          </DetailParagraph>
        </ContactInfo>
      </RightSection>
    </ServiceOverviewContainer>
  );
}

export default ServiceOverview;