import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';

const FormContainer = styled.div`
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  height: 100px;
`;

const Select = styled.select`
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  padding: 0.75rem;
  background-color: #2A77f1;
  color: white;
  font-size: 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #225fcc;
  }
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  font-size: 1.25rem;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-size: 1.25rem;
`;

function ContactForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute('6LdLeDAqAAAAABrhL97ltNcE8vG6FwIG5XEOdQvn', { action: 'FORM_SUBMIT' }).then((recaptchaToken) => {
        // Add the token to your form data
        const formData = new FormData(e.target); // `e.target` is the form element
        formData.append('g-recaptcha-response', recaptchaToken);


        fetch('https://lively-voice-963e.8w5gcrnfj8.workers.dev/', {
          method: 'POST',
          body: formData,
        })
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            emailjs.sendForm('service_piigw6i', 'template_10prbpa', e.target)
              .then(() => {
                setIsSubmitted(true);
                e.target.reset();
              })
              .catch((emailError) => {
                console.error('EmailJS failed:', emailError);
                setError('Failed to send your message via email. Please try again later.');
              });
          } else {
            setError('reCAPTCHA validation failed. Please try again');
          }
        })
        .catch((recaptchaError) => {
          console.error('reCAPTCHA validation error: ', recaptchaError);
          setError('Failed to validate reCAPTCHA. Please try again later');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
      });
    });
  };

  return (
    <FormContainer>
      {isSubmitted ? (
        <SuccessMessage>Thank you! Your message has been sent.</SuccessMessage>
      ) : (
        <Form id="contact-form" onSubmit={handleSubmit}>
          <FormTitle>Tell us about your next windows and doors project</FormTitle>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            name="user_name"
            id="name"
            required
          />

          <Label htmlFor="email">Email Address</Label>
          <Input
            type="email"
            name="user_email"
            id="email"
            required
          />

          <Label htmlFor="phone">Phone</Label>
          <Input
            type="tel"
            name="phone"
            id="phone"
            required
          />

          <Label htmlFor="projectType">Type of Project</Label>
          <Select
            name="projectType"
            id="projectType"
            required
          >
            <option value="">Select an option</option>
            <option value="Replacement">Replacement</option>
              <option value="Remodel">Remodel</option>
              <option value="Retrofit">Retrofit</option>
            <option value="New Construction">New Construction</option>
          </Select>

          <Label htmlFor="startDate">When do you plan to start your project?</Label>
          <Input
            type="date"
            name="startDate"
            id="startDate"
          />

          <Label htmlFor="message">Message</Label>
          <Textarea
            name="message"
            id="message"
            placeholder="Tell us more about your project..."
            required
          />

          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Submit Quote Request'}
          </SubmitButton>
        </Form>
      )}
    </FormContainer>
  );
}

export default ContactForm;